import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Panel.module.css';
import { DIMENSIONS } from '../../../config';
import { ReactComponent as SpatialIcon } from '../../../assets/icons/spatial.svg';
import { ReactComponent as GovernanceIcon } from '../../../assets/icons/governance.svg';
import { ReactComponent as EconomicIcon } from '../../../assets/icons/economic.svg';
import { ReactComponent as SocialIcon } from '../../../assets/icons/social.svg';
import { ReactComponent as EnvironmentalIcon } from '../../../assets/icons/environmental.svg';
import { ReactComponent as TriangleIcon } from '../../../assets/icons/triangle.svg';
import logo from '../../../assets/icons/logo.png';

const Panel = (props) => {
	const {
		selectedDimension,
		layers,
		selectedLayerId,
		selectedGroupIds,
		subpanelOpen,
		dimensionChanged,
		layerChanged,
		subpanelButtonClicked,
		t
	} = props;

	const icons = {
		spatial: <SpatialIcon className={classes.icon} />,
		governance: <GovernanceIcon className={classes.icon} />, 
		economic: <EconomicIcon className={classes.icon} />,
		social: <SocialIcon className={classes.icon} />, 
		environmental: <EnvironmentalIcon className={classes.icon} />
	};

	const logoWithButton = (
		<div className={classes.logoContainer}>
			<img src={logo} className={classes.logo} alt="agenda urbana" />
			<button 
				className={classes.subpanelButton}
				onClick={subpanelButtonClicked}
			>
				<TriangleIcon className={classes.triangleIcon} />
			</button>
		</div>
	);

	const panelBlocks = DIMENSIONS.map(dimension => (
		<button 
			key={dimension}
			className={`panel-block ${classes.panelBlock} ${classes[dimension]} ${(subpanelOpen && dimension === selectedDimension) ? classes.selected : ''}`}
			onClick={dimensionChanged.bind(this, dimension)}
		>
			{icons[dimension]}
		</button>
	));

	const layersInSelectedGroups = {};
	const selectedGroupIdsInDimension = selectedGroupIds.filter(groupId => layers[groupId].dimension === selectedDimension);
	selectedGroupIdsInDimension.forEach(groupId => {
		layersInSelectedGroups[groupId] = Object.keys(layers)
			.filter(layerId => layers[layerId].dimension === selectedDimension)
			.filter(layerId => layers[layerId].groupId === groupId)
			.map(layerId => (
				<button 
					key={layerId}
					className={`panel-block has-text-centered ${classes.subpanelBlock} ${classes.inGroup} ${(layerId === selectedLayerId) ? classes.selected : ''}`}
					onClick={layerChanged.bind(this, layerId)}
				>
					<span>{layers[layerId].name}</span>
				</button>
			));
	});

	const subpanelBlocks = (
		<nav className={`panel ${classes.subpanel} ${subpanelOpen ? classes[selectedDimension] : ''}`}>
			{subpanelOpen ?
				<React.Fragment>
					<h1 className={classes.subpanelHeader}>{t(`dimensionsObj.${selectedDimension}`)}</h1>
					{Object.keys(layers)
						.filter(layerId => layers[layerId].dimension === selectedDimension && layers[layerId].groupId === null)
						.map(layerId => (
							<React.Fragment key={layerId}>
								{layers[layerId].isGroup ? (
									<button 
										className={`panel-block has-text-centered ${classes.subpanelBlock} ${classes.isGroup} ${(layerId === selectedLayerId) ? classes.selected : ''}`}
										onClick={layerChanged.bind(this, layerId)}
									>
										<TriangleIcon className={`${classes.groupTriangle} ${(layerId in layersInSelectedGroups) ? classes.selected : ''}`} />
										<span className={classes.isGroup}>{layers[layerId].name}</span>
									</button>
								) : (
									<button 
										className={`panel-block has-text-centered ${classes.subpanelBlock} ${(layerId === selectedLayerId) ? classes.selected : ''}`}
										onClick={layerChanged.bind(this, layerId)}
									>
										<span>{layers[layerId].name}</span>
									</button>
								)}
								{layers[layerId].isGroup && (layerId in layersInSelectedGroups) && (
									layersInSelectedGroups[layerId]
								)}
							</React.Fragment>
					))}
				</React.Fragment>
			: null}
		</nav>
	);

	return (
		<nav className={`panel ${classes.panel} ${subpanelOpen ? classes.subpanelOpen : ''}`}>
			{logoWithButton}
			<div className={classes.panelBlocksContainer}>
				{panelBlocks}
				{subpanelBlocks}
			</div>
		</nav>
	);
};

export default withTranslation()(Panel);