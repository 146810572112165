import React from 'react';
import classes from './Spinner.module.css';

const Spinner = ({ display }) => (
	display ? (
		<div className={classes.loader}>
			<div className={classes.ball1}></div>
			<div className={classes.ball2}></div>
		</div>
	) : null
);

export default Spinner;