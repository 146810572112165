import React, { Component } from 'react';
import { scaleLinear } from 'd3-scale';
import { max, extent, histogram } from 'd3-array';
import { select } from 'd3-selection';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import 'd3-transition';


const BAR_PADDING = 1;
const CHART_PADDING = (window.innerWidth > 700)
  ? { bottom: 33, top: 15, left: 37, right: 15 }
  : { bottom: 30, top: 15, left: 32, right: 15 };
const NUM_BINS = 12;
const NUM_TICKS = (window.innerWidth > 700) ? 10 : 6;
const CHART_COLOR = '#8affed';

class D3Chart extends Component {
   
  svg = React.createRef();

  componentDidMount() {
    this.setupAxesAndLabels();
    this.drawChart();
  }

  componentDidUpdate(prevProps) {
    if(this.props.selectedLayerData !== prevProps.selectedLayerData) {
      this.drawChart();
    }
  }

  setupAxesAndLabels() {
    const { width, height } = this.props;
    const svg = select(this.svg.current);

    svg.append("g")
        .classed("d3-x-axis", true)
        .attr("transform", `translate(0, ${height - CHART_PADDING.bottom})`);

    svg.append("text")
        .classed("d3-x-axis-label", true)
        .attr("x", width * 0.55)
        .attr("y", height - 3);

    svg.append("g")
        .classed("d3-y-axis", true)
        .attr("transform", `translate(${CHART_PADDING.left}, 0)`);

    svg.append("text")
        .classed("d3-y-axis-label", true)
        .attr("transform", "rotate(-90)")
        .attr("x", -height * 0.4)
        .attr("y", 8);
  }

  drawChart() {
    const { width, height, selectedLayerData, xAxisLabel, yAxisLabel, layerProperty, statisticsThresholds } = this.props;
    const svg = select(this.svg.current);

    const data = selectedLayerData.features.map(f => ({ value: f.properties[layerProperty] }));

    // Create X-Scale
    let xExtent = extent(data, d => d.value);
    let xScale = scaleLinear()
      .domain(xExtent)
      .rangeRound([CHART_PADDING.left, width - CHART_PADDING.right]);

    const thresholds = statisticsThresholds ? statisticsThresholds : xScale.ticks(NUM_BINS);

    let histogramGenerator = histogram()
      .domain(xScale.domain())
      .thresholds(thresholds)
      .value(d => d.value);
    let bins = histogramGenerator(data);

    // Create Y-Scale
    let yMax = max(bins, d => d.length);
    let yScale = scaleLinear()
      .domain([0, yMax])
      .range([height - CHART_PADDING.bottom, CHART_PADDING.top]);

    // Create Axes
    const numTicks = statisticsThresholds ? statisticsThresholds.length + 1 : NUM_TICKS;
    select(".d3-x-axis")
      .call(axisBottom(xScale).ticks(numTicks).tickFormat(format("~r")).tickSizeOuter(0));

    /* If yMax is 10 or less include yMax ticks only and no more */
    if(yMax <= 10) {
      select(".d3-y-axis")
        .call(axisLeft(yScale).ticks(yMax).tickFormat(format("~s")).tickSizeOuter(0))
        .selectAll("text")
        .style("text-anchor", "end");
    } else {
      select(".d3-y-axis")
        .call(axisLeft(yScale).tickFormat(format("~s")).tickSizeOuter(0))
        .selectAll("text")
        .style("text-anchor", "end");
    }
    
    select(".d3-x-axis-label")
      .transition()
      .duration(500)
      .text(xAxisLabel)
        .transition()
        .duration(1000)
        .style("opacity", 1);

    select(".d3-y-axis-label")
      .transition()
      .duration(500)
      .text(yAxisLabel)
        .transition()
        .duration(1000)
        .style("opacity", 1);

    // GENERAL UPDATE PATTERN
    let bars = svg
      .selectAll("rect")
      .data(bins);
    
    bars
      .exit()
      .transition()
      .remove();

    bars
      .enter()
      .append("rect")
      .merge(bars)
        .transition()
        .duration(1000)
        .delay((d, i) => i * 50)
        .attr("x", d => xScale(d.x0) + 1)
        .attr("width", d => (d.x1 === d.x0) ? 0 : xScale(d.x1) - xScale(d.x0) - BAR_PADDING)
        .attr("y", d => yScale(d.length))
        .attr("height", d => height - CHART_PADDING.bottom - yScale(d.length))
        .attr("fill", CHART_COLOR);
  }

  render() {
    const { width, height } = this.props;
    return (
      <svg 
        ref={this.svg}
        width={width}
        height={height}>
      </svg>
    );
  }
}

export default D3Chart;