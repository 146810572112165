import React, { Component } from 'react';
import { db, storage } from '../../config/firebase';
import { decode } from 'geobuf';
import Pbf from 'pbf';
import Map from './Map/Map';
import MapStyleButton from './MapStyleButton/MapStyleButton';
import Panel from './Panel/Panel';
import LegendStatisticsBox from './LegendStatisticsBox/LegendStatisticsBox';
import Spinner from './Spinner/Spinner';
import { DIMENSIONS, INITIAL_MAP } from '../../config';

class AgendaUrbana extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapStyle: INITIAL_MAP.style,
      layers: {},
      selectedLayerId: null,
      selectedGroupIds: [],
      selectedLayerData: null,
      mapStyleMenuOpen: false,
      subpanelOpen: false,
      selectedDimension: DIMENSIONS[0],
      legendStatisticsBoxMinimized: (window.innerWidth <= 650) ? true : false,
      legendTabSelected: true,
      loadingSpinner: true
    }
  }

  componentDidMount() {
    const layers = {};
    db.collection('layers')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          layers[doc.id] = doc.data();
        });
        this.setState({ layers });
      })
      .catch(err => console.log(err));
  }

  mapStyleButtonClickedHandler = () => {
    this.setState(state => ({ mapStyleMenuOpen: !state.mapStyleMenuOpen }));
  }

  mapStyleChangedHandler = (newMapStyle) => {
    this.setState({ mapStyle: newMapStyle, mapStyleMenuOpen: false });
  }

  dimensionChangedHandler = (dimension) => {
    this.setState(state => (state.selectedDimension === dimension)
        ? { subpanelOpen: !state.subpanelOpen}
        : { selectedDimension: dimension, subpanelOpen: true}
    );
  }

  loadSelectedLayerData = () => {
    const selectedLayerId = this.state.selectedLayerId;
    if(!selectedLayerId) return;
    const storageFilename = this.state.layers[this.state.selectedLayerId].storageFilename;
    storage.ref().child(storageFilename).getDownloadURL()
      .then(url => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'arraybuffer';
        xhr.onload = () => {
          const arraybuffer = xhr.response;
          const geojson = decode(new Pbf(arraybuffer));
          /* Don't update layerData state if the selectedLayer has changed while decoding */
          if(selectedLayerId !== this.state.selectedLayerId) return;
          this.setState({ selectedLayerData: geojson });
        };
        xhr.onerror = () => {
          console.log("An error occurred during the transaction");
          this.setState({ loadingSpinner: false });
        }
        xhr.open('GET', url);
        xhr.send();
      })
      .catch(err => console.log(err));   
  }

  layerChangedHandler = (layerId) => {
    if(!this.state.layers[layerId].isGroup) {
      this.setState(state => {
        if(state.selectedLayerId !== layerId) {
          return { selectedLayerId: layerId, selectedLayerData: null, loadingSpinner: true };
        } else {
          return { selectedLayerId: null, selectedLayerData: null, loadingSpinner: false};
        }
      }, this.loadSelectedLayerData);
    } else {
      this.setState(state => {
        let updatedSelectedGroupIds = null;
        if(state.selectedGroupIds.includes(layerId)) {
          updatedSelectedGroupIds = state.selectedGroupIds.filter(id => id !== layerId);
        } else {
          updatedSelectedGroupIds = [...state.selectedGroupIds, layerId];
        }
        return { selectedGroupIds: updatedSelectedGroupIds };
      });      
    }
  }

  subpanelButtonClickedHandler = () => {
    this.setState(state => state.subpanelOpen
      ? { subpanelOpen: false}
      : (state.selectedLayerId)
        ? { subpanelOpen: true, selectedDimension: state.layers[state.selectedLayerId].dimension }
        : { subpanelOpen: true }
    );
  }

  tabClickedHandler = (legendTab) => {
    this.setState(state => (!state.legendStatisticsBoxMinimized)
      ? (legendTab !== state.legendTabSelected)
        ? { legendTabSelected: legendTab }
        : { legendStatisticsBoxMinimized: true }
      : { legendStatisticsBoxMinimized: false, legendTabSelected: legendTab }
    );
  }

  stopLoadingSpinnerHandler = () => {
  	this.setState({ loadingSpinner: false });
  }

  render() {
    const { 
      mapStyle,
      layers,
      selectedLayerId,
      selectedGroupIds,
      selectedLayerData,
      mapStyleMenuOpen, 
      subpanelOpen, 
      selectedDimension, 
      legendStatisticsBoxMinimized,
      legendTabSelected,
      loadingSpinner
    } = this.state;

    const style = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%'
    };

    return (
      <div style={style}>
        <Map 
          mapStyle={mapStyle}
          layers={layers}
          selectedLayerId={selectedLayerId}
          selectedLayerData={selectedLayerData}
          loadingSpinner={loadingSpinner}
          stopLoadingSpinner={this.stopLoadingSpinnerHandler}
        />
        <MapStyleButton
          currentMapStyle={mapStyle}
          mapStyleMenuOpen={mapStyleMenuOpen}
          buttonClicked={this.mapStyleButtonClickedHandler}
          mapStyleChanged={this.mapStyleChangedHandler} 
        />
        <Panel 
          selectedDimension={selectedDimension}
          layers={layers}
          selectedLayerId={selectedLayerId}
          selectedGroupIds={selectedGroupIds}
          subpanelOpen={subpanelOpen}
          dimensionChanged={this.dimensionChangedHandler}
          layerChanged={this.layerChangedHandler}
          subpanelButtonClicked={this.subpanelButtonClickedHandler}
        />
        <LegendStatisticsBox
          layers={layers}
          selectedLayerId={selectedLayerId}
          selectedLayerData={selectedLayerData}
          legendStatisticsBoxMinimized={legendStatisticsBoxMinimized}
          legendTabSelected={legendTabSelected}
          subpanelOpen={subpanelOpen}
          tabClicked={this.tabClickedHandler}
        />
				<Spinner display={loadingSpinner} />
      </div>
    );
  }
}

export default AgendaUrbana;