import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Login.module.css';

const Login = (props) => {
  const { 
    loginFormData, 
    loading, 
    errorMessage, 
    loginFormChanged, 
    loginFormSubmitted, 
    openResetPasswordModal, 
    t
  } = props;

	return(
    <div className={`container has-text-centered ${classes.loginContainer}`}>
      <h1 className={`title has-text-grey ${classes.loginHeader}`}>{t('login.title')}</h1>
      <div className={`box ${classes.box}`}>
        <form onSubmit={loginFormSubmitted}>
          {errorMessage &&
            <p className={classes.errorMessage}>{errorMessage}</p>
          }
          <div className="field">
            <div className="control">
              <input 
                className="input"
                name="email" 
                type="email" 
                placeholder={t('login.email')} 
                autoFocus=""
                value={loginFormData.email}
                onChange={loginFormChanged}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input 
                className="input"
                name="password"
                type="password" 
                placeholder={t('login.password')}
                value={loginFormData.password}
                onChange={loginFormChanged}
              />
            </div>
          </div>
          <button className={`button is-block is-info is-fullwidth ${loading ? 'is-loading' : ''}`}>
            {t('login.login')}
          </button>
        </form>
      </div>
      <a href='/admin' onClick={openResetPasswordModal}>{t('login.forgot')}</a>
    </div>
	);
}

export default withTranslation()(Login);