import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

// Initialize Firebase
const config = {
  apiKey: "AIzaSyC1fhjycZgVwzIZbOhi6ehUwjt7GXroFD0",
  authDomain: "agenda-urbana-algeciras.firebaseapp.com",
  databaseURL: "https://agenda-urbana-algeciras.firebaseio.com",
  projectId: "agenda-urbana-algeciras",
  storageBucket: "agenda-urbana-algeciras.appspot.com",
  messagingSenderId: "909607812338"
};
firebase.initializeApp(config);

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();