import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './AdminPanel.module.css';
import AdminSpinner from './AdminSpinner';
import { DIMENSIONS, LAYER_UPLOAD_STATUS } from '../../../config';
import { ReactComponent as SpatialIcon } from '../../../assets/icons/spatial.svg';
import { ReactComponent as GovernanceIcon } from '../../../assets/icons/governance.svg';
import { ReactComponent as EconomicIcon } from '../../../assets/icons/economic.svg';
import { ReactComponent as SocialIcon } from '../../../assets/icons/social.svg';
import { ReactComponent as EnvironmentalIcon } from '../../../assets/icons/environmental.svg';
import { ReactComponent as UpdateIcon } from '../../../assets/icons/update.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';

const icons = {
	spatial: <SpatialIcon className={classes.icon} />,
	governance: <GovernanceIcon className={classes.icon} />, 
	economic: <EconomicIcon className={classes.icon} />,
	social: <SocialIcon className={classes.icon} />, 
	environmental: <EnvironmentalIcon className={classes.icon} />
};

const AdminPanel = (props) => {
	const { 
		layers, 
		selectedDimension, 
		layerToUpdate, 
		selectedFile, 
		uploadStatus, 
		uploadErrorMessage,
		dimensionChanged,
		layerSelected,
		fileChanged,
		fileUploaded,
		logout,
		t
	} = props;

	const panel = (
		<nav className="panel">
			<p className={`panel-heading ${classes.panelHeader}`}>
				{t('dimensions')}
			</p>
			{DIMENSIONS.map(dimension => (
				<button
					key={dimension}
					className={`panel-block button ${classes.panelBtn} ${(selectedDimension === dimension) ? classes.selected : ''}`}
					onClick={dimensionChanged.bind(this, dimension)}
				>
					<span className={classes.iconContainer}>{icons[dimension]}</span>
					<span>{t(`dimensionsObj.${dimension}`)}</span>
				</button>
			))}
		</nav>
	);

	const layersList = (layers) ? (
		<div>
			<p className={classes.selectLayerText}><i>{t('admin.selectLayer')}</i></p>
  		{Object.keys(layers)
				.filter(layerId => layers[layerId].dimension === selectedDimension)
				.map(layerId => (
					<div 
						key={layerId}
						className={`card ${classes.layer}`}
						onClick={layerSelected.bind(this, layerId)}
					>
						<UpdateIcon className={classes.updateIcon} />
						<span className={classes.layerName}>
							{layers[layerId].name}
						</span>
					</div>))}  			
		</div>
		) : null;

	const logoutButton = (
		<button 
			className={`button is-primary is-outlined ${classes.logoutBtn}`}
			onClick={logout}
		>
			{t('admin.logout')}
		</button>
	);

	let modalUploadMessage = null;
	switch(uploadStatus) {
		case LAYER_UPLOAD_STATUS.SELECTED:
			modalUploadMessage = t('admin.uploadConfirmationMessage').split('\n').map((line, i) => (
				<p key={i} className={classes.messageWarning}>{line}</p>
			));
			break;
		case LAYER_UPLOAD_STATUS.SENDING:
			modalUploadMessage = <AdminSpinner />;
			break;
		case LAYER_UPLOAD_STATUS.SUCCESS:
			modalUploadMessage = (
				<p className={classes.messageSuccess}>
					<SuccessIcon className={classes.successIcon} />
					{t('admin.uploadSuccessMessage')}
				</p>
			);
			break;
		case LAYER_UPLOAD_STATUS.ERROR:
			modalUploadMessage = (
				<p className='has-text-danger'>{uploadErrorMessage}</p>
			);
			break;
		default:
			break;
	}

	const modal = (layerToUpdate) ? (
		<div className={`modal ${layerToUpdate ? 'is-active' : ''}`}>
			<div 
				className="modal-background"
				onClick={layerSelected.bind(this, null)}>
			</div>
				<div className={`modal-card ${classes.modal}`}>
					<section className={`modal-card-body ${classes.modalContent}`}>
						<button 
							className={`delete ${classes.modalCloseBtn}`} 
							aria-label="close" 
							onClick={layerSelected.bind(this, null)}>
						</button>
						<p className={classes.modalHeader}>
							<span>{t('admin.modalLayerToUpdate')}</span>
							<span className={classes.layerToUpdate}>{layers[layerToUpdate].name}</span>
						</p>
						<p><i>{t('admin.modalMessage')}</i></p>
						<div className={`file ${classes.fileUpload}`}>
							<label className="file-label">
							<input 
								className="file-input" 
								type="file" 
								name="geojson" 
								accept=".geojson" 
								onChange={fileChanged}
							/>
							<span className="file-cta">
								<UploadIcon className={classes.uploadIcon} />
								<span className="file-label">
									{t('admin.modalSelectFile')}
								</span>
							</span>
							{selectedFile && (
								<span className="file-name">{selectedFile.name}</span>
							)}
							</label>
						</div>
						<div className={classes.uploadMessageContainer}>
							{modalUploadMessage}
						</div>
					</section>
					<footer className="modal-card-foot">
						<button 
							className="button is-primary" 
							disabled={(selectedFile && uploadStatus !== LAYER_UPLOAD_STATUS.SENDING) ? false : true}
							onClick={(uploadStatus !== LAYER_UPLOAD_STATUS.SUCCESS)
								? fileUploaded
								: layerSelected.bind(this, null)}
							>
								{(uploadStatus !== LAYER_UPLOAD_STATUS.SUCCESS) 
									? t('admin.modalBtnUpdate') 
									: t('admin.modalBtnOk')}
						</button>
						<button 
							className="button" 
							onClick={layerSelected.bind(this, null)}
						>
							{t('admin.modalBtnCancel')}
						</button>
					</footer>
				</div>
		</div>
	) : null;

	return (
		<div className={classes.admin}>
			<h1 className={classes.title}>{t('admin.panel')}</h1>
			<div className={`container ${classes.mainContainer}`}>
				<div className="columns is-variable is-7">
					<div className="column is-5">
						{panel}
					</div>
					<div className="column is-7">
						{layersList}
					</div>
				</div>
			</div>
			{logoutButton}
			{modal}
		</div>
	);
}

export default withTranslation()(AdminPanel);