import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './MapStyleButton.module.css';
import { ReactComponent as LayersIcon } from '../../../assets/icons/layers.svg';
import { ReactComponent as TriangleIcon } from '../../../assets/icons/triangle.svg';
import { MAP_STYLES } from '../../../config';

const MapStyleButton = ({ currentMapStyle, mapStyleMenuOpen, buttonClicked, mapStyleChanged, t }) => (
	<div className={classes.container}>
		<button
			className={`button ${classes.mapStyleButton}`}
			onClick={buttonClicked}>
			<LayersIcon className={classes.layersIcon}/>
		</button>
		<aside className={`menu ${classes.menu} ${mapStyleMenuOpen ? classes.open : ''}`}>
			<TriangleIcon className={classes.triangleIcon}/>
			<ul className="menu-list">
				{MAP_STYLES.map(mapStyle => (
					<li key={mapStyle}>
						<button 
							className={`button ${classes.menuButton} ${mapStyle === currentMapStyle ? classes.selected : ''}`}
							onClick={mapStyleChanged.bind(this, mapStyle)}
						>
							{t(`mapStyles.${mapStyle}`)}
						</button>
					</li>
				))}
			</ul>
		</aside>
	</div>
);

export default withTranslation()(MapStyleButton);