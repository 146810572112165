import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import { INITIAL_MAP, BASE_LINE_WIDTH, BASE_LINE_COLOR, BASE_LINE_OPACITY, MAP_LEGEND_DEFAULT_COLOR, MAP_LEGEND_FALLBACK_COLOR, MAP_LEGEND_COLORS_OPACITY, MAP_CIRCLE_RADIUS, MAP_LINE_WIDTH, SUBLAYER_PROPERTY, BUILDING_FLOOR_HEIGHT } from '../../../config';
import BASE_LINE_GEOJSON from '../../../assets/layers/baseLine.json';
import stripes from '../../../assets/images/stripes.png';
import grid from '../../../assets/images/grid.png';

mapboxgl.accessToken = 'pk.eyJ1IjoiY2FydG9tZXRyaWNzIiwiYSI6ImNqOGJ2ZXIzazAxd3kyd3AyMDVrOGpzNWkifQ.KwvwFfoDOeLnjR1gEHO8tg';

class Map extends Component {

  map;
  mapContainer = React.createRef();
  popup;

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/mapbox/' + this.props.mapStyle + '-v9',
      center: INITIAL_MAP.center,
      zoom: INITIAL_MAP.zoom,
      maxBounds: INITIAL_MAP.maxBounds,
      attributionControl: false
    });
    this.map.addControl(new mapboxgl.AttributionControl({ customAttribution: ['Developed by <a href="https://cartometrics.com" target="_blank"><strong>Cartometrics</strong></a>'] }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
    this.map.loadImage(stripes, (err, image) => {
      if (err) throw err;
      this.map.addImage('stripes', image);
    });
    this.map.loadImage(grid, (err, image) => {
      if (err) throw err;
      this.map.addImage('grid', image);
    });

    this.map.on('style.load', () => {
      this.addBaseLine();
      if(this.props.selectedLayerData) {
        this.addLayer(this.props.selectedLayerId, this.props.selectedLayerData);
      }
    });

    this.map.on('idle', () => {
      if(this.props.loadingSpinner) {
        const { layers, selectedLayerId } = this.props;
        if(selectedLayerId && layers[selectedLayerId].geometryType === 'Height') {
          this.map.easeTo({ pitch: 60 }); 
        }
        this.props.stopLoadingSpinner();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.mapStyle !== prevProps.mapStyle) {
      this.map.setStyle('mapbox://styles/mapbox/' + this.props.mapStyle + '-v9');
    }
    if((this.props.selectedLayerData !== prevProps.selectedLayerData) && 
      this.props.selectedLayerData !== null) {
        this.addLayer(this.props.selectedLayerId, this.props.selectedLayerData);
    }
    if((this.props.selectedLayerId !== prevProps.selectedLayerId) && 
      prevProps.selectedLayerId !== null) {
        this.removeLayer(prevProps.selectedLayerId);
        if(this.popup) this.popup.remove();
    }
  }

  addBaseLine() {
    this.map.addSource('baseLine', {
      type: 'geojson',
      data: BASE_LINE_GEOJSON
    });

    this.map.addLayer({
      "id": "baseLine",
      "type": "line",
      "source": "baseLine",
      "paint": {
        "line-width": BASE_LINE_WIDTH,
        "line-color": BASE_LINE_COLOR,
        "line-opacity": BASE_LINE_OPACITY
      }
    });
  }

  generateLayerOptionsObject(geometryType, fillColor, borderColor) {
    let layerOptions = {};

    switch(geometryType) {
      case 'Point':
        layerOptions = {
          'type': 'circle',
          'paint': {
            'circle-radius': MAP_CIRCLE_RADIUS,
            'circle-color': fillColor,
            'circle-opacity': MAP_LEGEND_COLORS_OPACITY
          }
        };
        break;
      case 'LineString':
        layerOptions = {
          'type': 'line',
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          'paint': {
            'line-width': MAP_LINE_WIDTH,
            'line-color': fillColor,
            'line-opacity': MAP_LEGEND_COLORS_OPACITY
          }
        };
        break;
      case 'Height':
        layerOptions = {
          'type': 'fill-extrusion',
          'paint': {
            'fill-extrusion-color': fillColor,
            'fill-extrusion-height': ['*', BUILDING_FLOOR_HEIGHT, ['get', 'EQUIVALT']],
            'fill-extrusion-base': 0,
            'fill-extrusion-opacity': MAP_LEGEND_COLORS_OPACITY
          }
        };
        break;
      case 'Polygon':
      case 'MultiPolygon':
        layerOptions = {
          'type': 'fill',
          'paint': {
            'fill-color': fillColor,
            'fill-opacity': MAP_LEGEND_COLORS_OPACITY
          }
        };
        if(borderColor) layerOptions['paint']['fill-outline-color'] = borderColor;
        break;
      case 'Stripes':
        layerOptions = {
          'type': 'fill',
          'paint': {
            'fill-pattern': 'stripes'
          }
        };
        if(borderColor) layerOptions['paint']['fill-outline-color'] = borderColor;
        break;
      case 'Grid':
        layerOptions = {
          'type': 'fill',
          'paint': {
            'fill-pattern': 'grid'
          }
        };
        if(borderColor) layerOptions['paint']['fill-outline-color'] = borderColor;
        break;
      default:
        layerOptions = {
          'type': 'fill',
          'paint': {
            'fill-color': fillColor,
            'fill-opacity': MAP_LEGEND_COLORS_OPACITY
          }
        };
        if(borderColor) layerOptions['paint']['fill-outline-color'] = borderColor;
        break;
    }

    return layerOptions;
  }

  addLayer(selectedLayerId, selectedLayerData) {
    this.map.addSource(selectedLayerId, {
      type: 'geojson',
      data: selectedLayerData
    });

    const geometryType = this.props.layers[selectedLayerId].geometryType;
    const layerProperty = this.props.layers[selectedLayerId].layerProperty;
    let fillColor = MAP_LEGEND_DEFAULT_COLOR;
    const borderColor = this.props.layers[selectedLayerId].layerBorderColor || null;
    const bins = this.props.layers[selectedLayerId].bins;
    const binsType = this.props.layers[selectedLayerId].binsType;
    if(binsType === 'step' || binsType === 'match') {
      fillColor = [ binsType, ['get', layerProperty] ];
      if(binsType === 'step') fillColor.push(bins[0].color);
      bins.forEach(bin => {
        fillColor.push(bin.x0);
        fillColor.push(bin.color);
      });
      if(binsType === 'match') fillColor.push(MAP_LEGEND_FALLBACK_COLOR);
    }
    else if(binsType === 'singleBin') {
      fillColor = bins[0].color;  
    }

    if(geometryType !== 'Multilayer') {
      const layerOptions = this.generateLayerOptionsObject(geometryType, fillColor, borderColor);
      this.map.addLayer({
        'id': selectedLayerId,
        'source': selectedLayerId,
        ...layerOptions
      });
      this.map.on('click', selectedLayerId, this.addPopupOnClick);
      this.map.on('mouseenter', selectedLayerId, this.changeCursorOnMouseEnter);
      this.map.on('mouseleave', selectedLayerId, this.changeCursorOnMouseLeave);
    }
    else {
      bins.slice().sort((a, b) => b.sublayerId - a.sublayerId).forEach(bin => {
        const layerOptions = this.generateLayerOptionsObject(bin.geometryType, bin.color, bin.borderColor);
        layerOptions['filter'] = ['==', ['get', SUBLAYER_PROPERTY], bin.sublayerId];
        const sublayerId = selectedLayerId + '-' + bin.sublayerId;
        this.map.addLayer({
          'id': sublayerId,
          'source': selectedLayerId,
          ...layerOptions
        });
        this.map.on('click', sublayerId, this.addPopupOnClick);
        this.map.on('mouseenter', sublayerId, this.changeCursorOnMouseEnter);
        this.map.on('mouseleave', sublayerId, this.changeCursorOnMouseLeave);
      });
    }
  }

  removeLayer(prevLayerId) {
    const prevGeometryType = this.props.layers[prevLayerId].geometryType;
    if(prevGeometryType !== 'Multilayer' && this.map.getLayer(prevLayerId)) {
      this.map.off('click', prevLayerId, this.addPopupOnClick);
      this.map.off('mouseenter', prevLayerId, this.changeCursorOnMouseEnter);
      this.map.off('mouseleave', prevLayerId, this.changeCursorOnMouseLeave);
      this.map.removeLayer(prevLayerId);
      this.map.removeSource(prevLayerId);
      if(prevGeometryType === 'Height') {
        this.map.easeTo({ pitch: 0 });
      }
    }
    else if(this.map.getLayer(prevLayerId + '-1')) {
      for(let i = 1; i <= this.props.layers[prevLayerId].bins.length; i++) {
        const sublayerId =  prevLayerId + '-' + i;
        this.map.off('click', sublayerId, this.addPopupOnClick);
        this.map.off('mouseenter', sublayerId, this.changeCursorOnMouseEnter);
        this.map.off('mouseleave', sublayerId, this.changeCursorOnMouseLeave);
        this.map.removeLayer(sublayerId);
        this.map.removeSource(prevLayerId);     
      }
    }
  }

  addPopupOnClick = (e) => {
    if(this.popup) this.popup.remove();
    let popupContent = document.createElement('div');
    const properties = e.features[0].properties;
    if(properties['capa']) delete properties['capa'];
    popupContent.innerHTML = '<h1><strong>Información</strong></h1>' +
      Object.keys(properties).map(p => `<p>${p}: ${properties[p]}</p>`).join('');

    this.popup = new mapboxgl.Popup({ offset: 10 })
      .setLngLat(e.lngLat)
      .addTo(this.map)
      .setDOMContent(popupContent);
    popupContent.parentNode.querySelector('.mapboxgl-popup-close-button').classList.add('delete');
  }

  changeCursorOnMouseEnter = (e) => {
    // Change the cursor to a pointer when the mouse is over the places layer.
    this.map.getCanvas().style.cursor = 'pointer';
  }

  changeCursorOnMouseLeave = (e) => {
    // Change the cursor to a pointer when the mouse is over the places layer.
    this.map.getCanvas().style.cursor = '';
  }

  render() {
    const mapContainerStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%'
    };
    return (
      <div ref={this.mapContainer} style={mapContainerStyle}></div>
    );
  }
}

export default Map;