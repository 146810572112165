import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './PasswordReset.module.css';
import { PASSWORD_RESET_STATUS } from '../../../config';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';

const PasswordReset = (props) => {
	const { 
    isModalOpen,
    email,
    passwordResetStatus,
    errorMessage,
    closeModal,
    loginFormChanged,
    resetPassword,
		t 
	} = props;

	let message = null;
	switch(passwordResetStatus) {
		case PASSWORD_RESET_STATUS.ERROR:
			message = <p className={classes.errorMessage}>{errorMessage}</p>;
			break;
		case PASSWORD_RESET_STATUS.SUCCESS:
			message = (
				<p className={classes.messageSuccess}>
					<SuccessIcon className={classes.successIcon} />
					{t('reset.successMessage')}
				</p>);
			break;
		default:
			break;
	}

	return(
		<div className={`modal ${isModalOpen ? 'is-active' : ''}`}>
			<div className="modal-background"	onClick={closeModal}></div>
		  <div className={`modal-content ${classes.modalContent}`}>
  			<button className={`delete ${classes.modalCloseBtn}`} onClick={closeModal}></button>
  			<p className={classes.modalText}>{t('reset.text')}</p>
				<form onSubmit={(passwordResetStatus !== PASSWORD_RESET_STATUS.SUCCESS) ? resetPassword : closeModal}>
          <div className="field">
            <div className="control">
              <input 
                className="input"
                name="email" 
                type="email" 
                placeholder={t('login.email')} 
                value={email}
                onChange={loginFormChanged}
              />
            </div>
          </div>
          <button className={`button is-block is-info is-fullwidth ${(passwordResetStatus === PASSWORD_RESET_STATUS.SENDING) ? 'is-loading' : ''}`}>
            {(passwordResetStatus !== PASSWORD_RESET_STATUS.SUCCESS) ? t('reset.reset') : t('reset.ok')}
          </button>
        </form>
				{message}
		  </div>
		</div>
	);
}

export default withTranslation()(PasswordReset);