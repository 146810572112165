export const MAP_STYLES = ['streets', 'satellite', 'dark'];
export const DIMENSIONS = ['spatial', 'environmental', 'social', 'governance', 'economic'];
export const INITIAL_MAP = {
	style: MAP_STYLES[0],
	center: [-5.448, 36.133],
	zoom: 10,
	maxBounds: [
		[-6.117877, 35.939449], // Southwest coordinates
		[-4.987845, 36.771665]  // Northeast coordinates
	]
};
export const BASE_LINE_WIDTH = 4;
export const BASE_LINE_COLOR = '#e92121';
export const BASE_LINE_OPACITY = 0.5;
export const MAP_LEGEND_DEFAULT_COLOR = '#2e9489';
export const MAP_LEGEND_FALLBACK_COLOR = '#ffffff';
/*export const MAP_LEGEND_COLORS = ['#2e9489', '#359c6a', '#d0c16e', '#d29755', '#d66c3e'];*/
export const MAP_LEGEND_COLORS_OPACITY = 0.75;
export const MAP_CIRCLE_RADIUS = 5;
export const MAP_LINE_WIDTH = 2;
export const MAX_GEOJSON_FILE_SIZE_BYTES = 104857600;  // 100MB
export const LAYER_UPLOAD_STATUS = { 
	SELECTED: "selected",
	SENDING: "sending",
	SUCCESS: "success",
	ERROR: "error"
};
export const LAYER_UPDATE_BACKEND_STATUS = {
	UPDATING: 'updating',
	SUCCESS: 'success',
	ERROR: 'error' 
};
export const AUTH_STATUS = {
	NOT_AUTHENTICATED: 'notAuthenticated',
	AUTHENTICATING: 'authenticating',
	AUTHENTICATED: 'authenticated'
};
export const PASSWORD_RESET_STATUS = {
	SENDING: 'sending',
	SUCCESS: 'success',
	ERROR: 'error'
};
export const SUBLAYER_PROPERTY = 'capa';
export const BUILDING_FLOOR_HEIGHT = 3; // meters