import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AgendaUrbana from './components/AgendaUrbana/AgendaUrbana';
import Admin from './components/Admin/Admin';

const App = () => (
	<Switch>
		<Route path="/admin" exact component={Admin} />
		<Route path="/" exact component={AgendaUrbana} />
		<Redirect to="/" />
	</Switch>
);

export default App;