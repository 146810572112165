import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import GJValidator from 'geojson-validation';
import Login from './Login/Login';
import AdminPanel from './AdminPanel/AdminPanel';
import PasswordReset from './PasswordReset/PasswordReset';
import { db, storage, auth } from '../../config/firebase';
import { DIMENSIONS, MAX_GEOJSON_FILE_SIZE_BYTES, LAYER_UPLOAD_STATUS, LAYER_UPDATE_BACKEND_STATUS, AUTH_STATUS, PASSWORD_RESET_STATUS } from '../../config';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	authStatus: null,
      authErrorMessage: null,
    	loginFormData: {email: '', password: ''},
      isResetPasswordModalOpen: false,
      passwordResetStatus: null,
      passwordResetErrorMessage: null,
    	layers: null,
    	selectedDimension: DIMENSIONS[0],
    	layerToUpdate: null,
    	selectedFile: null,
    	uploadStatus: null,
    	uploadErrorMessage: null
    };
    /* We use it to unsubscribe to the Firestore realtime listener once the layer 
    has been updated correctly or the update has failed */
    this.firestoreUnsubscribe = null;
  }

  componentDidMount() {
    // Set up observer on Firebase auth object
    auth.onAuthStateChanged(user => {
		  if (user) {
        // Load layers data from Firestore
        const layers = {};
        db.collection('layers').get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              if(!doc.data().isGroup) {
                layers[doc.id] = {
                  name: doc.data().name,
                  dimension: doc.data().dimension
                };
              }
            });
            this.setState({ layers, authStatus: AUTH_STATUS.AUTHENTICATED, authErrorMessage: null });
          })
          .catch(err => {
            this.setState({ authStatus: AUTH_STATUS.NOT_AUTHENTICATED });
          });
		  } else {
		    this.setState({ authStatus: AUTH_STATUS.NOT_AUTHENTICATED });
		  }
		});
  }

  /* LOGIN */
  loginFormChangedHandler = (e) => {
  	const newLoginFormData = { ...this.state.loginFormData };
  	newLoginFormData[e.target.name] = e.target.value;
  	this.setState({ loginFormData: newLoginFormData })
  }

  loginFormSubmittedHandler = (e) => {
  	e.preventDefault();
  	const { email, password } = this.state.loginFormData;
  	this.setState({ authStatus: AUTH_STATUS.AUTHENTICATING });
  	auth.signInWithEmailAndPassword(email, password)
  		.catch(err => {
  			let authErrorMessage = null;
  			switch(err.code) {
  				case 'auth/user-not-found':
						authErrorMessage = this.props.t(`login.errorMessages.email`);
            break;
  				case 'auth/wrong-password':
  					authErrorMessage = this.props.t(`login.errorMessages.password`);
            break;
  				default:
  					authErrorMessage = this.props.t(`login.errorMessages.default`);
            break;
  			}
				this.setState({ 
					authStatus: AUTH_STATUS.NOT_AUTHENTICATED,
          authErrorMessage,
					loginFormData: { email: email , password: '' }
				});
			});
  }

  logoutHandler = () => {
  	auth.signOut()
  		.then(() => {
		  	this.props.history.push('/');
			});
  }

  openResetPasswordModalHandler = (e) => {
    e.preventDefault();
    this.setState({ isResetPasswordModalOpen: true });
  }  

  closeResetPasswordModalHandler = (e) => {
    e.preventDefault();
    this.setState({ 
      isResetPasswordModalOpen: false,
      passwordResetStatus: null,
      passwordResetErrorMessage: null
    });
  }

  resetPasswordHandler = (e) => {
    e.preventDefault();
    this.setState({ passwordResetStatus: PASSWORD_RESET_STATUS.SENDING });
    auth.sendPasswordResetEmail(this.state.loginFormData.email)
      .then(() => {
        this.setState({ passwordResetStatus: PASSWORD_RESET_STATUS.SUCCESS });
      })
      .catch(err => {
        let passwordResetErrorMessage = null;
        if(err.code === "auth/user-not-found") {
          passwordResetErrorMessage = this.props.t(`reset.errorMessages.email`);
        } else {
          passwordResetErrorMessage = this.props.t(`reset.errorMessages.default`);
        }
        this.setState({ 
          passwordResetStatus: PASSWORD_RESET_STATUS.ERROR,
          passwordResetErrorMessage 
        });
      });
  }


  /* ADMIN PANEL */
  dimensionChangedHandler = dimension => {
  	this.setState({ selectedDimension: dimension });
  }

  layerSelectedHandler = layerId => {
  	if(layerId !== null) {
  		this.setState({ layerToUpdate: layerId });
  	} else {
  		this.setState({ layerToUpdate: null, selectedFile: null, uploadStatus: null, uploadErrorMessage: null });
  	}
  }

  fileChangedHandler = event => {
  	const selectedFile = event.target.files[0];
  	let uploadErrorMessage = null;
  	if(selectedFile) {
	  	if(selectedFile.size > MAX_GEOJSON_FILE_SIZE_BYTES) {
	  		uploadErrorMessage = this.props.t(`admin.errorMessages.size`);
	  		this.setState({ uploadStatus: LAYER_UPLOAD_STATUS.ERROR, uploadErrorMessage });
	  	} else {
	  		const fileReader = new FileReader();
				fileReader.onload = () => {
					try {
						const geojson = JSON.parse(fileReader.result);
						if(!GJValidator.valid(geojson)){
							uploadErrorMessage = this.props.t(`admin.errorMessages.geojson`);
			  			this.setState({ uploadStatus: LAYER_UPLOAD_STATUS.ERROR, uploadErrorMessage });
						} else {
							this.setState({ selectedFile, uploadStatus: LAYER_UPLOAD_STATUS.SELECTED });
						}				
					}
					catch(err) {
			  		uploadErrorMessage = this.props.t(`admin.errorMessages.geojson`);
			  		this.setState({ uploadStatus: LAYER_UPLOAD_STATUS.ERROR, uploadErrorMessage });					
					}
				}
				fileReader.readAsText(selectedFile);
	  	}
  	}
  }

  fileUploadedHandler = () => {
  	this.setState({ uploadStatus: LAYER_UPLOAD_STATUS.SENDING });
  	const { selectedFile, layerToUpdate } = this.state;

		db.collection('layers').doc(layerToUpdate).update({ updateStatus: LAYER_UPDATE_BACKEND_STATUS.UPDATING })
			.then(() => {
  			return storage.ref().child(selectedFile.name).put(selectedFile, { customMetadata: { layerToUpdate: layerToUpdate }})
			})
  		.then(snapshot => {				
				/* Set up FIRESTORE REALTIME LISTENER */
		  	this.firestoreUnsubscribe = db.collection('layers').doc(layerToUpdate).onSnapshot(doc => {
		  		const layerUpdateBackendStatus = doc.data().updateStatus;
		  		if(layerUpdateBackendStatus === LAYER_UPDATE_BACKEND_STATUS.SUCCESS) {
  					this.firestoreUnsubscribe();			
  					this.setState({ uploadStatus: LAYER_UPLOAD_STATUS.SUCCESS });
		  		}
		  		else if(layerUpdateBackendStatus === LAYER_UPDATE_BACKEND_STATUS.ERROR) {
						const uploadErrorMessage = this.props.t(`admin.errorMessages.server`);
					  this.setState({ uploadStatus: LAYER_UPLOAD_STATUS.ERROR, uploadErrorMessage });
		  		}
				});
  		})
  		.catch(err => {
  			console.log(err);
  			const uploadErrorMessage = this.props.t(`admin.errorMessages.server`);
				this.setState({ uploadStatus: LAYER_UPLOAD_STATUS.ERROR, uploadErrorMessage });
  		});
  }

  render() {
  	const {
  		authStatus,
      authErrorMessage,
	  	loginFormData,
      isResetPasswordModalOpen,
      passwordResetStatus,
      passwordResetErrorMessage,
	  	layers, 
	  	selectedDimension, 
	  	layerToUpdate, 
	  	selectedFile, 
	  	uploadStatus, 
	  	uploadErrorMessage 
  	} = this.state;

  	let admin = null;
  	if(authStatus === AUTH_STATUS.NOT_AUTHENTICATED || authStatus === AUTH_STATUS.AUTHENTICATING) {
  		admin = <React.Fragment>
        <Login
  				loginFormData={loginFormData}
  				loading={authStatus === AUTH_STATUS.AUTHENTICATING}
  				errorMessage={authErrorMessage}
  				loginFormChanged={this.loginFormChangedHandler}
  				loginFormSubmitted={this.loginFormSubmittedHandler}
          openResetPasswordModal={this.openResetPasswordModalHandler}
  		  />
        <PasswordReset
          isModalOpen={isResetPasswordModalOpen}
          email={loginFormData.email}
          passwordResetStatus={passwordResetStatus}
          errorMessage={passwordResetErrorMessage}
          closeModal={this.closeResetPasswordModalHandler}
          loginFormChanged={this.loginFormChangedHandler}
          resetPassword={this.resetPasswordHandler}
        />
      </React.Fragment>;
  	} else if(authStatus === AUTH_STATUS.AUTHENTICATED) {
  		admin = <AdminPanel
				layers={layers}
				selectedDimension={selectedDimension}
				layerToUpdate={layerToUpdate}
				selectedFile={selectedFile}
				uploadStatus={uploadStatus}
				uploadErrorMessage={uploadErrorMessage}
				dimensionChanged={this.dimensionChangedHandler}
				layerSelected={this.layerSelectedHandler}
				fileChanged={this.fileChangedHandler}
				fileUploaded={this.fileUploadedHandler}
				logout={this.logoutHandler}
			/>;
		}

		return admin;
  }
}

export default withTranslation()(Admin);