import React from 'react';
import classes from './StatisticsTotals.module.css';

const StatisticsTotals = ({ totals }) => (
	<ul>
		{totals && totals.map(total => (
			<li className={classes.total} key={total.label}>
				<span className={classes.label}>{total.label + ':'}</span>
				<span className={classes.value}>{total.value}</span>
			</li>
		))}
	</ul>
);

export default StatisticsTotals;