import React from 'react';
import classes from './Legend.module.css';
import { MAP_LEGEND_COLORS_OPACITY } from '../../../config';
import stripes from '../../../assets/images/stripes-legend.png';
import grid from '../../../assets/images/grid-legend.png';

const Legend = ({ bins, geometryType, borderColor }) => (
	<div>
		{bins && bins.map(bin => {
			const backgroundImage = (bin.geometryType === 'Stripes') ? `url(${stripes})`
				: (bin.geometryType === 'Grid') ? `url(${grid})`
				: 'none';
			const border = (geometryType === 'Multilayer' && bin.borderColor) ? `1px solid ${bin.borderColor}`
				: borderColor ? `1px solid ${borderColor}`
				: 'none';
			return (
				<div className={classes.row} key={bin.label}>
					<div 
						className={classes.square} 
						style={{
							backgroundImage,
							backgroundColor: bin.color,
							opacity: MAP_LEGEND_COLORS_OPACITY,
							border
						}}>
					</div>
					<span className={classes.label}>{bin.label}</span>
				</div>
			);
		})}
	</div>
);

export default Legend;