import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './LegendStatisticsBox.module.css';
import D3Chart from './D3Chart';
import Legend from './Legend';
import StatisticsTotals from './StatisticsTotals';
import { ReactComponent as LegendIcon } from '../../../assets/icons/legend.svg';
import { ReactComponent as StatisticsIcon } from '../../../assets/icons/statistics.svg';

const LegendStatisticsBox = (props) => {
	const {
		layers,
		selectedLayerId,
		selectedLayerData,
		legendStatisticsBoxMinimized, 
		legendTabSelected, 
		subpanelOpen, 
		tabClicked,
		t
	} = props;

	const statisticsType = (selectedLayerId) ? layers[selectedLayerId].statisticsType : null;
	const statisticsThresholds = (selectedLayerId) ? layers[selectedLayerId].statisticsThresholds : null;
	const layerProperty = (selectedLayerId) ? layers[selectedLayerId].layerProperty : null;
	const axisLabel = (selectedLayerId) ? layers[selectedLayerId].axisLabel : null;
	const yearUpdated = (selectedLayerId) ? layers[selectedLayerId].yearUpdated : null;
	const bins = (selectedLayerId) ? layers[selectedLayerId].bins : null;
	const legendTitle = (selectedLayerId) ? layers[selectedLayerId].legendTitle : null;
	const geometryType = (selectedLayerId) ? layers[selectedLayerId].geometryType : null;
	const borderColor = (selectedLayerId) ? layers[selectedLayerId].layerBorderColor : null;

	const tabs = (
		<div className={`${classes.tabs}`}>
	  	<button 
	  		className={`button ${classes.tabButton} ${classes.legend} ${legendTabSelected ? classes.selected : ''}`}
	  		onClick={tabClicked.bind(this, true)}
	  	>
	  		<LegendIcon className={classes.icon}/>
	  	</button>
	  	<button 
	  		className={`button ${classes.tabButton} ${classes.statistics} ${legendTabSelected ? '' : classes.selected}`}
	  		onClick={tabClicked.bind(this, false)}
	  	>
	  		<StatisticsIcon className={classes.icon}/>
	  	</button>
		</div>	
	);

	const legendDisplay = (
		<div className={classes.display}>
			<div className={classes.displayHeader}>
				<h1 className={classes.title}>{t('legendStatisticsBox.legend')}</h1>
				{selectedLayerId && (
					<React.Fragment>
						<p className={classes.legendTitle}>{legendTitle}</p>
						<p className={classes.date}>{t('legendStatisticsBox.date') + yearUpdated}</p>
					</React.Fragment>
				)}
			</div>
			<div className={classes.legendContainer}>
				{selectedLayerId && (
					<Legend
						bins={bins.filter(bin => bin.label !== null)}
						geometryType={geometryType}
						borderColor={borderColor}
					/>
				)}
			</div>
		</div>
	);

	let statistics = null;
	if(selectedLayerData) {
		switch(statisticsType) {
			case 'totals':
				statistics = (
					<div className={classes.totalsContainer}>
						<StatisticsTotals
							totals={selectedLayerData.global ? selectedLayerData.global : null}
						/>
					</div>
				);
				break;
			case 'automatic':
			case 'custom':
				if(layerProperty) {
					statistics = (
						<div className={classes.chartContainer}>
							<D3Chart 
								width={(window.innerWidth > 700) ? 280 : 180}
								height={(window.innerWidth > 700) ? 140 : 110}
								selectedLayerData={selectedLayerData}
								layerProperty={layerProperty}
								xAxisLabel={axisLabel}
								yAxisLabel={t('legendStatisticsBox.yAxisLabel')}
								statisticsThresholds={(statisticsType === 'custom' && statisticsThresholds) ? statisticsThresholds : null}
							/>
						</div>
					);
				}
				break;
			default:
				break;
		}
	}

	const statisticsDisplay = (
		<div className={classes.display}>
			<div className={classes.displayHeader}>
				<h1 className={classes.title}>{t('legendStatisticsBox.statistics')}</h1>
				{selectedLayerData && (
					<p className={classes.date}>{t('legendStatisticsBox.date') + yearUpdated}</p>
				)}
			</div>
			{statistics}
		</div>
	);

	return (
		<div className={`${classes.container} ${subpanelOpen ? classes.subpanelOpen : '' } ${legendStatisticsBoxMinimized ? classes.boxMinimized : '' }`}>
			{tabs}
			<div className={`${classes.displayContainer}`}>
				{legendTabSelected 
					? legendDisplay
					: statisticsDisplay
				}
			</div>
		</div>
	);
};

export default withTranslation()(LegendStatisticsBox);